import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {LoginModalComponent} from '../../modal/login-modal/login-modal.component';
import {ConnectUserService} from '../../services/connect-user.service';
import csc from "country-state-city";
import {SignUpModalComponent} from "../../modal/sign-up-modal/sign-up-modal.component";
import {UsersService} from "../../services/users.service";

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

    public homeActive: boolean;
    public bsModalRef: BsModalRef;
    public userLogin: boolean;

    constructor(private router: Router, private modalService: BsModalService, private connectUserService: ConnectUserService, private usersService: UsersService) {
    }

    ngOnInit() {

        this.userLogin = !!sessionStorage.getItem('jwt');
        this.connectUserService.data.subscribe(data => {
            if (data !== null) {
                this.userLogin = data === 'login';
            }
        });

        this.homeActive = false;

        this.router.events.subscribe((val): any => {

            let routeData: any;
            routeData = val;

            if (routeData.url) {
                if (routeData.url.length === 1) {
                    this.homeActive = true;
                } else {
                    this.homeActive = false;
                }
            }
        });
    }

    openLoginModal() {

        const initialState = {
            title: 'Sign In'
        };

        this.bsModalRef = this.modalService.show(LoginModalComponent, {initialState});
        this.bsModalRef.content.closeBtnName = 'Close';

    }

    signUp() {
        if (sessionStorage.getItem('jwt')) {
            alert('1');
        } else {

            this.usersService.getPhoneCountryCode('https://geolocation-db.com/json/0f761a30-fe14-11e9-b59f-e53803842572').subscribe(val => {
                const initialState = {
                    country: val.country_name,
                    phonePrefix: csc.getCountryByCode(val.country_code).phonecode,
                    title: 'Sign up'
                };

                this.bsModalRef = this.modalService.show(SignUpModalComponent, {initialState});
                this.bsModalRef.content.closeBtnName = 'Close';
            }, val => {

            });

        }
    }

    LogOut() {
        sessionStorage.clear();
        localStorage.clear();
        this.connectUserService.updateUserStatus('logout');
        this.router.navigate(['.']);
    }

}
