import {Store, StoreConfig} from '@datorama/akita';
import {Injectable} from '@angular/core';

export interface UiState {
    token: string;
    lineCount: number;
    user: any;
    goeError: boolean;
}

export function createInitialState(): UiState {
    return {
        token: '',
        lineCount: 0,
        user: null,
        goeError: false
    };
}
@Injectable({
    providedIn: 'root'
})
@StoreConfig({name: 'ui'})
export class UiStore extends Store<UiState> {
    constructor(private uiStore: UiStore) {
        super(createInitialState());
    }

    updateToken(token: string) {
        this.update(state => ({
            token
        }));
    }

    updateLineCount(lineCount: number) {
        this.update(state => ({
            lineCount
        }));
    }

    updateUser(user: any) {
        this.update(state => ({
            user
        }));
    }

    updateGoeError(goeError: boolean) {
        this.update(state => ({
            goeError
        }));
    }
}
