import {Injectable} from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';

import {Observable, throwError} from 'rxjs';
import {map, catchError} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {ConnectUserService} from '../services/connect-user.service';
import {Router} from '@angular/router';
import {UiStore} from "../state/ui/ui.store";

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    constructor(private toastr: ToastrService, private connectUserService: ConnectUserService, private router: Router, private uiStore: UiStore) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    console.log('event--->>>', event);
                    // this.errorDialogService.openDialog(event);
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {

                if (error.status === 401) {
                    this.toastr.error('your session is time out, login again', 'session time out',
                        {timeOut: 2000});
                    sessionStorage.clear();
                    localStorage.clear();
                    this.connectUserService.updateUserStatus('logout');
                    this.router.navigate(['.']);
                } else if (error.status !== 400){
                    if (error.url.indexOf('geolocation') !== -1) {
                     this.uiStore.updateGoeError(true);
                    } else {
                        this.toastr.error('internal server error', 'server error',
                            {timeOut: 2000});
                    }

                }

                return throwError(error);
            }));
    }
}
