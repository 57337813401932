import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {AppRoutes} from './app.routes';
import {HomeComponent} from './main/components/home/home.component';
import {ContactComponent} from './main/components/contact/contact.component';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {ReactiveFormsModule} from '@angular/forms';
import {JwtInterceptor} from './shared/helpers/jwt.interceptor';
import {ModalModule} from 'ngx-bootstrap/modal';
import {SignUpMsgComponent} from './main/components/sign-up-msg/sign-up-msg.component';
import {ToastrModule} from 'ngx-toastr';
import {HttpErrorInterceptor} from './shared/interceptor/http-error.interceptor';
import {LoaderInterceptor} from './shared/interceptor/http-loader.interceptor';
import {AkitaNgDevtools} from '@datorama/akita-ngdevtools';
import {SharedModule} from './shared/shared.module';

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        ContactComponent,
        SignUpMsgComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        SharedModule,
        AppRoutes,
        HttpClientModule,
        ReactiveFormsModule,
        ModalModule.forRoot(),
        ToastrModule.forRoot(),
        AkitaNgDevtools.forRoot(),
    ],
    providers: [{provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true}],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    bootstrap: [AppComponent]
})
export class AppModule {
}

