import {RouterModule} from '@angular/router';
import {ModuleWithProviders} from '@angular/core';
import {HomeComponent} from './main/components/home/home.component';
import {ContactComponent} from './main/components/contact/contact.component';
import {SignUpMsgComponent} from './main/components/sign-up-msg/sign-up-msg.component';

const routes = [
    {
        path: '',
        redirectTo: '/',
        pathMatch: 'full'
    },
    {
        path: '',
        component: HomeComponent,
    },
    {
        path: 'products',
        loadChildren : './main/modules/products/products.module#ProductsModule'
    },
    {
        path: 'prices',
        loadChildren : './main/modules/prices/prices.module#PricesModule'
    },
    {
        path: 'using',
        loadChildren : './main/modules/using/using.module#UsingModule'
    },
    {
        path: 'contact',
        component: ContactComponent,
    },
    {
        path: 'signUp-success',
        component: SignUpMsgComponent,
    },
    {
        path: 'activate/:id',   loadChildren : './main/modules/activate/activate.module#ActivateModule'
    },
    {
        path: 'reset-password/:id',
        loadChildren : './main/modules/reset-password/reset-password.module#ResetPasswordModule'
    },
    {
        path: 'private-area',
        loadChildren : './main/modules/private-area/private-area.module#PrivateAreaModule'
    },
];


export const AppRoutes: ModuleWithProviders = RouterModule.forRoot(routes);
