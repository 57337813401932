import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ICreateOrderRequest, IPayPalConfig} from 'ngx-paypal';
import {PurchaseService} from '../../services/purchase.service';
import {Purchase} from '../../model/purchase';

@Component({
    selector: 'app-pay-pal',
    templateUrl: './pay-pal.component.html',
    styleUrls: ['./pay-pal.component.scss']
})
export class PayPalComponent implements OnInit {

    public payPalConfig?: IPayPalConfig;
    public _price: string = null;
    public purchase: Purchase;

    @Input() lineCount: string;
    @Input('price')
    set price(value: string) {
        this.initConfig(value);

        setTimeout(() => {
            this._price = value;
            this.purchase = {
                lineCount: this.lineCount,
                orderPrice: this._price
            };

           ///
        }, 0);
        setTimeout(() => {
            this._price = value;
            this.purchase = {
                lineCount: this.lineCount,
                orderPrice: this._price
            };


        }, 0);


    }

    @Output() finishOrder = new EventEmitter();

    constructor(private purchaseService: PurchaseService) {
    }

    ngOnInit() {

    }



    private initConfig(price: string): void {

        this.payPalConfig = {
            currency: 'EUR',
            clientId: 'Aebib9MHoDhcGTf5IyARAoG2_I9AcTJjSaFyv2chTgA3dlJ8nwpVgqnP1ljyJK84ycCXBufAG8tGSJiK',
            createOrderOnClient: (data) => <ICreateOrderRequest>{
                intent: 'CAPTURE',
                purchase_units: [
                    {
                        amount: {
                            currency_code: 'EUR',
                            value: price,
                            breakdown: {
                                item_total: {
                                    currency_code: 'EUR',
                                    value: price
                                }
                            }
                        },
                        items: [
                            {
                                name: 'Enterprise Subscription',
                                quantity: '1',
                                category: 'DIGITAL_GOODS',
                                unit_amount: {
                                    currency_code: 'EUR',
                                    value: price,
                                },
                            }
                        ]
                    }
                ]
            },
            advanced: {
                commit: 'true'
            },
            style: {
                label: 'paypal',
                layout: 'vertical'
            },
            onApprove: (data, actions) => {
 console.log('onApprove - transaction was approved, but not authorized', data, actions);
                actions.order.get().then(details => {


                    this.purchaseService.purchase('api/order/purchase', this.purchase).subscribe(response => {
                        // sessionStorage.setItem('lineCount', response.lineCount);

                        this.finishOrder.emit(response.lineCount);
                    });

                    console.log('onApprove - you can get full order details inside onApprove: ', details);
                });
            },
            onClientAuthorization: (data) => {

                console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);


            },
            onCancel: (data, actions) => {

                console.log('OnCancel', data, actions);
            },
            onError: err => {

                console.log('OnError', err);
            },
            onClick: (data, actions) => {

                console.log('onClick', data, actions);
            },
        };
    }

}
