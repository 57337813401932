import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Purchase} from '../model/purchase';

@Injectable({
  providedIn: 'root'
})
export class PurchaseService {

    constructor(private http: HttpClient) {
    }

    purchase(url: string, purchase: Purchase) {

        return this.http.post<Purchase>(url, purchase);
    }

    getUserOrders(url: string) {

        return this.http.post<any>(url, {});
    }
}
