import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import {enableAkitaProdMode, persistState} from '@datorama/akita';
import {defineCustomElements} from 'xml-viewer-component/dist/loader';

export function getBaseUrl() {
    return document.getElementsByTagName('base')[0].href;
}

const storage = persistState();

const providers = [
    {provide: 'BASE_URL', useFactory: getBaseUrl, deps: []},
    {provide: 'persistStorage', useValue: storage}
];

if (environment.production) {
    enableProdMode();
    enableAkitaProdMode();
}

platformBrowserDynamic(providers).bootstrapModule(AppModule)
    .catch(err => console.error(err));
defineCustomElements(window);
