import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {BsModalRef} from "ngx-bootstrap";
import {faWindowClose} from '@fortawesome/free-solid-svg-icons';
import {Subject} from "rxjs";

@Component({
  selector: 'app-buy-line-message',
  templateUrl: './buy-line-message.component.html',
  styleUrls: ['./buy-line-message.component.scss']
})
export class BuyLineMessageComponent implements OnInit {

  public list: any[] = [];
  public title: string;
  public lineCount: string;
  public guid: string;
  public closeBtnName: string;
  public resetPasswordForm: FormGroup;
  public submitted = false;
  public faWindowClose = faWindowClose;
  public onClose: Subject<boolean>;

  constructor(public bsModalRef: BsModalRef,
              private formBuilder: FormBuilder,
              @Inject('BASE_URL') private baseUrl: string) {
  }

  ngOnInit() {
    this.onClose = new Subject();
  }

   onConfirm(val: boolean): void {
    this.onClose.next(val);
    this.bsModalRef.hide();
  }

}
