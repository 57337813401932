import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {BsModalRef} from "ngx-bootstrap";
import {faWindowClose} from '@fortawesome/free-solid-svg-icons';
import {Subject} from "rxjs";

@Component({
  selector: 'app-token-expired-modal',
  templateUrl: './token-expired-modal.component.html',
  styleUrls: ['./token-expired-modal.component.scss']
})
export class TokenExpiredModalComponent implements OnInit {


  public title: string;
  public closeBtnName: string;
  public faWindowClose = faWindowClose;
  public onClose: Subject<boolean>;

  constructor(public bsModalRef: BsModalRef, @Inject('BASE_URL') private baseUrl: string) {
  }

  ngOnInit() {
    document.documentElement.addEventListener('click',  this.hideIfIsBackdropClick.bind(this));

    this.onClose = new Subject();
  }

  onConfirm(val: boolean): void {
    this.onClose.next(val);
    this.bsModalRef.hide();
  }

  hideIfIsBackdropClick(event: any): void {
    if (event.target.classList.contains('modal')) {
      this.onConfirm(true);
    }
  }
}
