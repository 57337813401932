import {Component, Inject, OnInit} from '@angular/core';
import {BsDropdownConfig, BsModalRef} from 'ngx-bootstrap';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {UsersService} from '../../services/users.service';
import {Router} from '@angular/router';
import {faWindowClose} from '@fortawesome/free-solid-svg-icons';
import {SignInUser} from '../../model/sign-in-user';

@Component({
    selector: 'app-forgot-password-modal',
    templateUrl: './forgot-password-modal.component.html',
    styleUrls: ['./forgot-password-modal.component.scss'],
    providers: [{provide: BsDropdownConfig, useValue: {isAnimated: true, autoClose: true}}]
})
export class ForgotPasswordModalComponent implements OnInit {

    public title: string;
    public closeBtnName: string;
    public forgotPasswordForm: FormGroup;
    public submitted = false;
    public faWindowClose = faWindowClose;
    public mailNotExist: boolean;
    public resetPasswordMailSend: boolean;

    constructor(public bsModalRef: BsModalRef,
                @Inject('BASE_URL') private baseUrl: string,
                private router: Router,
                private usersService: UsersService) {
    }

    ngOnInit() {
        this.mailNotExist = false;
        this.resetPasswordMailSend = false;
        this.forgotPasswordForm = new FormGroup({
            email: new FormControl('', [Validators.required, Validators.email])
        });
    }

    get f() {
        return this.forgotPasswordForm.controls;
    }

    sendMailResetPassword(data: SignInUser) {

        this.submitted = true;

        if (this.forgotPasswordForm.invalid) {
            return false;
        }

        this.usersService.forgetPassword('api/users/forgetPassword', data).subscribe(response => {
            this.resetPasswordMailSend = true;

        }, err => {
            this.mailNotExist = true;

        });
    }

}
