import {Component, Inject, OnInit} from '@angular/core';
import {BsModalRef} from "ngx-bootstrap";
import {faWindowClose} from '@fortawesome/free-solid-svg-icons';
import {Subject} from "rxjs";

@Component({
  selector: 'app-no-cobol-file-upload-message',
  templateUrl: './no-cobol-file-upload-message.html',
  styleUrls: ['./no-cobol-file-upload-message.scss']
})
export class NoCobolFileUploadMessageComponent implements OnInit {

  public list: any[] = [];
  public title: string;
  public lineCount: string;
  public guid: string;
  public closeBtnName: string;
  public submitted = false;
  public faWindowClose = faWindowClose;
  public onClose: Subject<boolean>;

  constructor(public bsModalRef: BsModalRef,
              @Inject('BASE_URL') private baseUrl: string) {
  }

  ngOnInit() {
    this.onClose = new Subject();
  }

   onConfirm(val: boolean): void {
    this.onClose.next(val);
    this.bsModalRef.hide();
  }

}
