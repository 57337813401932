import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeaderComponent} from './components/header/header.component';
import {FooterComponent} from './components/footer/footer.component';
import {RouterModule} from '@angular/router';
import {SignUpModalComponent} from './modal/sign-up-modal/sign-up-modal.component';
import {ReactiveFormsModule} from '@angular/forms';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {LoginModalComponent} from './modal/login-modal/login-modal.component';
import {ForgotPasswordModalComponent} from './modal/forgot-password-modal/forgot-password-modal.component';
import { ResetPasswordModalComponent } from './modal/reset-password-modal/reset-password-modal.component';
import { PayPalComponent } from './components/pay-pal/pay-pal.component';
import {NgxPayPalModule} from 'ngx-paypal';
import { LoaderComponent } from './components/loader/loader.component';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { BuyLineMessageComponent } from './modal/buy-line-message/buy-line-message.component';
import { TokenExpiredModalComponent } from './modal/token-expired-modal/token-expired-modal.component';
import {TabsModule} from 'ngx-bootstrap';
import {NoCobolFileUploadMessageComponent} from './modal/no-cobol-file-upload-message/no-cobol-file-upload-message';
@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        FontAwesomeModule,
        NgxPayPalModule,
        AutocompleteLibModule,
        TabsModule
    ],
    declarations: [HeaderComponent, FooterComponent, SignUpModalComponent, LoginModalComponent, ForgotPasswordModalComponent, ResetPasswordModalComponent, PayPalComponent, LoaderComponent, BuyLineMessageComponent, TokenExpiredModalComponent, NoCobolFileUploadMessageComponent],
    exports: [HeaderComponent, FooterComponent, PayPalComponent, LoaderComponent],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    entryComponents: [SignUpModalComponent, LoginModalComponent, ForgotPasswordModalComponent, ResetPasswordModalComponent, BuyLineMessageComponent, TokenExpiredModalComponent, NoCobolFileUploadMessageComponent]
})
export class SharedModule {
}
