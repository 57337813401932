import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SignUpUser} from '../model/sign-up-user';
import {SignInUser} from '../model/sign-in-user';
import {UpdateUser} from '../model/update-user';
import {ConvertFile} from '../model/convert-file';

@Injectable({
    providedIn: 'root'
})
export class UsersService {
apiUrl = '';

    constructor(private http: HttpClient) {
    }

    signUpNewUser(url: string, user: SignUpUser) {
        return this.http.post<SignUpUser>(this.apiUrl + url, user);
    }

    signInUser(url: string, user: SignInUser) {
        return this.http.post<any>(this.apiUrl + url, user);
    }

    getPhoneCountryCode(url: string) {
        return this.http.get<any>(url);
    }

    userActivate(url: string) {
        return this.http.put<any>(this.apiUrl + url, null);
    }

    forgetPassword(url: string, email: any) {
        return this.http.post<any>(this.apiUrl + url, email);
    }

    resetPassword(url: string, password: any) {
        return this.http.put<any>(this.apiUrl + url, password);
    }

    updateUser(url: string, user: UpdateUser) {
        return this.http.put<SignUpUser>(this.apiUrl + url, user);
    }

    getUserArchiveFiles(url: string, userId: any) {
        return this.http.post<ConvertFile[]>(this.apiUrl + url, userId);
    }

    getNewToken(url: string) {
        return this.http.post<any>(this.apiUrl + url, {});
    }
}
