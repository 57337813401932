import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';


@Injectable({
    providedIn: 'root'
})
export class FileService {

    monthList: string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    constructor(private http: HttpClient) {
    }

    downloadXmlFile(url: string, data: any) {
        return this.http.post<ArrayBuffer>(url, data, {responseType: 'arrayBuffer' as 'json'});
    }

    getXmlFile(blob: any): void {

        const theDate = new Date();
        const day = theDate.getDate();
        const month = this.monthList[theDate.getMonth()];
        const year = theDate.getFullYear();
        const hour = theDate.getHours();
        const minute = theDate.getMinutes();

        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        const newBlob = new Blob([blob], {type: 'application/zip'});

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
            return;
        }

        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob);
        const link = document.createElement('a');
        link.href = data;
        link.download = 'XMLSource' + day + month + year + '_' + hour + '_' + minute + '.zip';
        link.click();

    }

    uploadCobolFiles(url: string, data: any) {

        // return this.http.post<ArrayBuffer>(url, data, { responseType: 'arrayBuffer' as 'json' });
        return this.http.post<any>(url, data);
    }

    removeCobolFile(url: string, data: any) {
        return this.http.post<any>(url, data);
    }

    convertCobolFile(url: string, data: any) {

        return this.http.post<ArrayBuffer>(url, data, { responseType: 'arrayBuffer' as 'json' });
    }

    removeArchiveConvertFile(url: string, data: any) {
        return this.http.post<any>(url, data);
    }

    getLineNumber(url: string, data: any) {
        return this.http.post<any>(url, data);
    }

}
