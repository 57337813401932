import {Component, Inject, OnInit} from '@angular/core';
import {BsDropdownConfig, BsModalRef} from 'ngx-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MustMatch} from '../../helpers/formValidators';
import {SignUpUser} from '../../model/sign-up-user';
import {FileService} from '../../services/file.service';
import {UsersService} from '../../services/users.service';
import {Router} from '@angular/router';
import {faWindowClose} from '@fortawesome/free-solid-svg-icons';
import {COUNTRIES} from '../../data/countries';

@Component({
    selector: 'app-sign-up-modal',
    templateUrl: './sign-up-modal.component.html',
    styleUrls: ['./sign-up-modal.component.scss'],
    providers: [{provide: BsDropdownConfig, useValue: {isAnimated: true, autoClose: true}}]
})
export class SignUpModalComponent implements OnInit {
    public list: any[] = [];
    public title: string;
    public country: string;
    public phonePrefix: string;
    public closeBtnName: string;
    public addUserForm: FormGroup;
    public submitted = false;
    public emailExist: boolean;
    public phoneOrMobileMissing: boolean;
    public faWindowClose = faWindowClose;
    public countries: any;
    public keyword = 'name';

    constructor(public bsModalRef: BsModalRef,
                private formBuilder: FormBuilder,
                @Inject('BASE_URL') private baseUrl: string,
                private fileService: FileService,
                private router: Router,
                private usersService: UsersService) {
    }

    ngOnInit() {
        this.countries = COUNTRIES;
        const time = new Date().getTime().toString();
        const f = time.substr(7);
        this.emailExist = false;
        this.phoneOrMobileMissing = false;
        this.addUserForm = this.formBuilder.group({
            firstName: [''],
            lastName: [''],
            email: ['', [Validators.required, Validators.email]],
            phone: ['', [Validators.pattern(/^[+]*[(]{0,1}[0-9]{6,15}[)]{0,1}[-\s\./0-9]*$/)]],
            mobile: ['', [Validators.pattern(/^[+]*[(]{0,1}[0-9]{6,15}[)]{0,1}[-\s\./0-9]*$/)]],
            address: [''],
            job: [''],
            comeFrom: [''],
            country: [this.country ? this.country : '', [Validators.required]],
            status: [0],
            UploadFolder: [f],
            password: ['', [Validators.required,
                Validators.pattern(/^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{6,8}$/)]],
            confirmPassword: ['', Validators.required]
        }, {
            validator: MustMatch('password', 'confirmPassword')
        });
    }

    get f() {
        return this.addUserForm.controls;
    }

    changeJob(e) {

        this.addUserForm.patchValue({
            job: e.target.value
        });

    }

    changeComeFrom(e) {
        this.addUserForm.patchValue({
            comeFrom: e.target.value
        });


    }

    addUser(data: SignUpUser) {

        this.submitted = true;
        this.emailExist = false;

        if (!data.phone && !data.mobile) {
            this.phoneOrMobileMissing = true;
        }
        if (this.addUserForm.invalid || this.phoneOrMobileMissing) {
            return false;
        }
        data.phone = data.phone ? '+' + this.phonePrefix + '-' + data.phone : '';
        data.mobile = data.mobile ? '+' + this.phonePrefix +  '-' + data.mobile : '';
        this.usersService.signUpNewUser('api/Users/register', data).subscribe(val => {
            this.bsModalRef.hide();
            localStorage.setItem('regMail', data.email);
            this.router.navigate(['/signUp-success']);

        }, val => {
            if (val.error.errCode === '100') {
                this.emailExist = true;
            }
        });
    }

    selectEvent(item) {

        this.addUserForm.patchValue({
            country: item.name
        });


        this.phonePrefix = item.phone;
    }
    onChangeSearch(val: string) {

        this.phonePrefix = '';
    }

    inputCleared(e) {
        this.phonePrefix = '';
    }
}
