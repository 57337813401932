import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ConnectUserService {

    private dataSource = new BehaviorSubject<string>(null);
    public data = this.dataSource.asObservable();

    constructor() {
    }

    updateUserStatus(data: string) {
        this.dataSource.next(data);
    }

}
