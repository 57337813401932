import {Component, Inject, OnInit} from '@angular/core';
import {BsDropdownConfig, BsModalRef, BsModalService} from 'ngx-bootstrap';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {UsersService} from '../../services/users.service';
import {Router} from '@angular/router';
import {faWindowClose} from '@fortawesome/free-solid-svg-icons';
import {SignInUser} from '../../model/sign-in-user';
import {ForgotPasswordModalComponent} from '../forgot-password-modal/forgot-password-modal.component';
import {ConnectUserService} from '../../services/connect-user.service';
import {UiStore} from '../../state/ui/ui.store';
import {TokenExpiredModalComponent} from '../token-expired-modal/token-expired-modal.component';

@Component({
    selector: 'app-login-modal',
    templateUrl: './login-modal.component.html',
    styleUrls: ['./login-modal.component.scss'],
    providers: [{provide: BsDropdownConfig, useValue: {isAnimated: true, autoClose: true}}]
})
export class LoginModalComponent implements OnInit {
    public list: any[] = [];
    public title: string;
    public closeBtnName: string;
    public signInUserForm: FormGroup;
    public submitted = false;
    public faWindowClose = faWindowClose;
    public invalidLogin: boolean;


    constructor(public bsModalRef: BsModalRef,
                @Inject('BASE_URL') private baseUrl: string,
                private router: Router,
                private modalService: BsModalService,
                private usersService: UsersService,
                private connectUserService: ConnectUserService,
                private uiStore: UiStore) {
    }

    ngOnInit() {
        this.invalidLogin = false;
        this.signInUserForm = new FormGroup({
            email: new FormControl('', [Validators.required, Validators.email]),
            password: new FormControl('', [Validators.required])
        });
    }

    get f() {
        return this.signInUserForm.controls;
    }

    signInUser(data: SignInUser) {

        this.submitted = true;

        if (this.signInUserForm.invalid) {
            return false;
        }
 
        this.usersService.signInUser('api/users/authenticate', data).subscribe(response => {

            const token = (<any>response).token;
            const lineCount = (<any>response).lineCount;
            const user = JSON.stringify(response.user);
            this.bsModalRef.hide();
             sessionStorage.setItem('jwt', token);
             sessionStorage.setItem('lineCount', lineCount);
             sessionStorage.setItem('user', user);
            this.uiStore.updateLineCount(lineCount);
            setTimeout(() => this.showTokenExpiredModal(), 1000 * 60 * 10); // 10 minute
            this.connectUserService.updateUserStatus('login');
            this.router.navigate(['/private-area']);
            this.invalidLogin = false;
        }, err => {
            this.invalidLogin = true;
        });
    }

    forgotPassword() {
        const initialState = {
            title: 'Forgot Password?'
        };
        this.bsModalRef.hide();
        this.bsModalRef = this.modalService.show(ForgotPasswordModalComponent, {initialState});
        this.bsModalRef.content.closeBtnName = 'Close';
    }

    private showTokenExpiredModal(){
        const initialState = {
            title: 'Session Expired',
        };
        this.bsModalRef = this.modalService.show(TokenExpiredModalComponent, {initialState});
        this.bsModalRef.content.closeBtnName = 'Close';

        this.bsModalRef.content.onClose.subscribe(result => {

            if(!result){
                sessionStorage.clear();
                localStorage.clear();
                this.connectUserService.updateUserStatus('logout');
                this.router.navigate(['.']);
            } else{
              this.usersService.getNewToken('api/users/getNewToken').subscribe((val =>{
                  setTimeout(() => this.showTokenExpiredModal(), 1000 * 20);
                  sessionStorage.setItem('jwt', val.token);
              }));
            }

        });

    }
}
