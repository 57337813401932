import {Component, Inject, OnInit} from '@angular/core';
import {BsDropdownConfig, BsModalRef} from 'ngx-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MustMatch} from '../../helpers/formValidators';
import {SignUpUser} from '../../model/sign-up-user';
import {FileService} from '../../services/file.service';
import {UsersService} from '../../services/users.service';
import {Router} from '@angular/router';
import {faWindowClose} from '@fortawesome/free-solid-svg-icons';
import {ResetPassword} from "../../model/reset-password";

@Component({
  selector: 'app-reset-password-modal',
  templateUrl: './reset-password-modal.component.html',
  styleUrls: ['./reset-password-modal.component.scss']
})
export class ResetPasswordModalComponent implements OnInit {

  public list: any[] = [];
  public title: string;
  public guid: string;
  public closeBtnName: string;
  public resetPasswordForm: FormGroup;
  public submitted = false;
  public faWindowClose = faWindowClose;

  constructor(public bsModalRef: BsModalRef,
              private formBuilder: FormBuilder,
              @Inject('BASE_URL') private baseUrl: string,
              private fileService: FileService,
              private router: Router,
              private usersService: UsersService) {
  }

  ngOnInit() {

    this.resetPasswordForm = this.formBuilder.group({

      password: ['', [Validators.required,
        Validators.pattern(/^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{6,8}$/)]],
      confirmPassword: ['', Validators.required]
    }, {
      validator: MustMatch('password', 'confirmPassword')
    });
  }

  get f() {
    return this.resetPasswordForm.controls;
  }


  resetPassword(data: ResetPassword) {

    this.submitted = true;



    if (this.resetPasswordForm.invalid) {
      return false;
    }

    this.usersService.resetPassword('api/Users/ResetPassword/' + this.guid, {password: data.password}).subscribe(val => {
      this.bsModalRef.hide();

    }, val => {

    });
  }

}
